<template>
  <PageLayout
    :title="$t('loginsuccess.title')"
  >
    <p class="login-success__paragraph">
      <span class="login-success__text">
        {{ $t('loginsuccess.subtitle') }}
      </span>
    </p>
    <base-button
      :customizable="{ maxWidth: '350px' }"
      :is-type="['simple', 'vanish']"
      data-testid="open-voicemod-button"
      :is-disabled="buttonDisabled"
      @onClick="onClickAction"
    >
      {{ $t('loginsuccess.button') }}
    </base-button>
  </PageLayout>
</template>

<script>
import { AuthClient } from '@/services/models/authentication';
import PageLayout from '@/components/PageLayout/PageLayout.vue';
import BaseButton from '@RepoComponent/BaseButton/BaseButton';
import { mParticleMetric } from '@/metrics';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import * as Sentry from '@sentry/vue';

export default {
    components: {
        PageLayout,
        BaseButton,
    },

    data() {
        return {
            buttonDisabled: false,
            vmGoCode: null,
        };
    },

    computed: {
        userId() {
            return this.$route.query.userId;
        },
        isNew() {
            return this.$route.query.isNew;
        },
    },

    async mounted() {
        this.$store.dispatch('user_info_store/axn_setShowProfile', true);
        if (this.userId) {
            this.vmGoCode = await this.getVMGoCode();
        }
    },

    methods: {
        onClickAction() {
            this.buttonDisabled = true;
            window.location.href = `vmstudio://login?code=${this.vmGoCode}&userId=${this.userId}&isNew=${this.isNew}`;
        },

        async getVMGoCode() {
            try {
                const { data } = await AuthClient.generateThirdPartyCode(this.userId, LOGIN_ACTIONS_CONSTANTS.VMGO);
                return data.code;
            } catch (error) {
                Sentry.captureMessage('Can\'t generate third party code for VM-GO');
                Sentry.captureException(error);
                mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED, {
                    login_error_reason: 'vmgo_third_party_code_generation_failed',
                });
                this.$router.push(ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR);
            }
        },
    },
};
</script>

<style src="./VMGoLoginSuccessView.scss" lang="scss" />
